import React from 'react'

export default function DangoTFT() {
    return(
        <section className="small-section">
            <h2>Dango Pattern</h2>
            <h2>!!! This pattern is under construction !!!</h2>
            <h3>Yarn needed:</h3>
            <p>
            <ul>
                <li>White</li>
                <li>Orange</li>
                <li>Yellow</li>
            </ul>
    (this pattern makes the basic dango, but there can be changes made in color to make other variations) <br></br>
</p>
<h3>Abbreviations: </h3>
<p>
    <ul>
        <li>sc = single crochet</li>
        <li>mr = magic ring</li>
        <li>inc = increase</li>
        <li>dec = decrease</li>
    </ul>

</p>

<h3>Head & Body</h3>
<p>
    Start with white <br />
    Round 1: 6 sc mr (6) <br />
    Round 2: 6 inc (12) <br />
    Round 3: (1 sc, 1 inc)* repeat around (18) <br />
    Round 4: (2 sc, 1 inc)* repeat around (24) <br />
    Round 5: (3 sc, 1 inc)* repeat around (30) <br />
    Round 6: (4 sc, 1 inc)* repeat around (36) <br />
    Round 7: (5 sc, 1 inc)* repeat around (42) <br />
    Round 8: (6 sc, 1 inc)* repeat around (48) <br />
    Round 9-17: 1 sc around (48) <br />
    Round 18: (6 sc, 1 dec)* repeat around (42) <br />
    Round 19: (5 sc, 1 dec)* repeat around (36) <br />
    Round 20: (4 sc, 1 dec)* repeat around (30) <br />
    Round 21: (3 sc, 1 dec)* repeat around (24) <br />
    Round 22: (2 sc, 1 dec)* repeat around (18) <br />
    Round 23: (1 sc, 1 dec)* repeat around (12) <br />
    Round 24: 1 dec around (6) <br />
</p>

<h3>Ears</h3>
<p>
    Start with white <br />
    Round 1: 6 sc mr (6) <br />
    Round 2: 6 sc (6) <br />
    Round 3: (1 sc, 1 inc)* repeat around (9) <br />
    Round 4: (2 sc, 1 inc)* repeat around (12) <br />
    Fasten off, leave tail for sewing
</p>

<h3>Tail</h3>
<p>
    Start with white <br />
    Round 1: 6 sc mr (6) <br />
    Round 2-6: 6 sc (6) <br />
    Fasten off, leave tail for sewing
</p>

<h3>The rest is wip - star</h3>

<p>Congrats on finishing!!! <br>
</br>
Tag: @dango.ploof on insta to show me your chonccs!!</p>
            <div className="patterns">
                <a href="/patterns">Back to Patterns</a>
            </div>
    </section>
    );
}