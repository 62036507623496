import React from 'react'

export default function Choncc() {
    return(
        <section className="small-section">
            <h2>Choncc (Small) Pattern</h2>
            <img width="20%" src="/images/patterns/choncc/choncc.jpg" alt="choncc"></img>
            <h3>Yarn needed:</h3>
            <p>
            <ul>
                <li>Pink</li>
                <li>White</li>
                <li>Blue</li>
                <li>Black</li>
            </ul>
    (this pattern makes the pink regular choncc, but there can be changes made in color to make other chonccs) <br></br>
</p>
<h3>Abbreviations: </h3>
<p>
    <ul>
        <li>sc = single crochet</li>
        <li>mr = magic ring</li>
        <li>inc = increase</li>
        <li>dec = decrease</li>
    </ul>

</p>

<h3>Head & Body</h3>
<p>
Start with pink <br></br>
Round 1: 6 sc in a mr (6) <br></br>
Round 2: 1 inc around (12) <br></br>
Round 3: (1 inc, 1 sc)* repeat around (18) <br></br>
Round 4: (2 sc, 1 inc)* repeat around (24) <br></br>
Round 5: (3 sc, 1 inc)* repeat around (30) <br></br>
Round 6-7: 1 sc around (30) <br></br>
Round 8: 12 sc, switch to white, 6 sc, switch to pink, 12 sc (30)  <br></br>
Round 9: 10 sc, switch to white, 1 dec, 6 sc, 1 dec, switch to pink, 10 sc (28) <br></br>
​Round 10: 1 dec, 7 sc, 1 dec, switch to white, 6 sc, switch to pink, 1 dec, 7 sc, 1 dec (24) <br></br>
Round 11: (2 sc, dec)* around, switch the white and pink to match previous rounds (18) <br></br>
Round 12: 2sc, (1 sc, inc)*2, switch to white, (1 sc, inc)*3, 1 sc, switch to pink, 1 inc, (1 sc, inc)*2 (26) <br></br>
Round 13: 2sc, (1 sc, inc)*2, 1 sc, switch to white, 1 inc, (1 sc, inc)*5, 1 sc, switch to pink, 1 inc, (1 sc, inc)*2, 2 sc (38) <br></br>
Round 14: 10 sc, switch to white, 7 sc, inc, 8 sc, switch to pink, 11 sc (38) <br></br>
Round 15: 12 sc, switch to white, 15 sc, switch to pink, 11 sc (38) <br></br>
Round 16-19: 12 sc, switch to white, 15 sc, switch to pink, 11 sc (38) <br></br>
Round 20: 13 sc, switch to white, 1 dec, 9 sc, 1 dec, switch to pink, 12 sc (36) <br></br>
Round 21: (4 sc, 1 dec)*2, 2sc, switch to white, 2 sc, 1 dec, 4 sc, 1 dec, switch to pink, (4 sc, 1 dec)*2 (30) <br></br>
Round 22: (3 sc, 1 dec)*2, 3sc, switch to white, 1 dec, 3 sc, 1 dec, switch to pink, (3 sc, 1 dec)*2 (24) <br></br>
Round 23: (2 sc, 1 dec)* around, only pink (18) <br></br>
Round 24: (1 sc, 1 dec)* around, only pink (12) <br></br>
Round 25: 1 dec around, only pink (6) <br></br>
Cut and sew off! <br></br>
</p>

<h3>Tail Ball</h3>
<p>
Start with pink <br></br>
Round 1: 4 sc in a mr (4) <br></br>
Round 2: 1 inc around (8) <br></br>
Round 3: 1 sc around (8) <br></br>
Round 4: 1 dec around (4) <br></br>
Cut and leave a long tail for sewing <br></br>
</p>

<h3>Tail</h3>
<p>
Start with pink <br></br>
Round 1: 5 sc in a mr (5) <br></br>
Round 2: 1 inc around (10) <br></br>
Round 3-7: 1 sc around (10) <br></br>
Cut and leave a long tail for sewing <br></br>
</p>

<h3>Side Horns (small) x2</h3>
<p>
Start with pink <br></br>
Round 1: 4 sc in a mr (4) <br></br>
Cut and leave a tail for sewing <br></br>
<br></br>
</p>
<h3>Side Horns (large) x2</h3>
<p>
Start with pink <br></br>
Round 1: 4 sc in a mr (4) <br></br>
Round 2: 4 sc around (4) <br></br>
Cut and leave a tail for sewing <br></br>
</p>

<h3>Big Horns x2</h3>
<p>
Start with blue <br></br>
Round 1: 6 sc in mr (6) <br></br>
Round 2: (1 sc, 1 inc)* repeat until end (9) <br></br>
Round 3-5: 1 sc around (9) <br></br>
Cut and leave a tail for sewing <br></br>
</p>

<h3>Arm x2</h3>
<p>
Start with pink <br></br>
 Round 1:  6 sc in mr (6) <br></br>
Round 2: (1 sc, 1 inc)* around (9) <br></br>
Round 3-8: 1 sc around (9) <br></br>
Cut and leave a long tail for sewing <br></br>
Use the blue to sew on 3 claws <br></br>
</p>

<h3>Legs x2</h3>
<p>
Start with white <br></br>
Round 1: 6 sc in mr (6) <br></br>
Switch to pink <br></br>
​Round 2: 1 inc around (12) <br></br>
Round 3-4: 1 sc around (12) <br></br>
Cut and leave a long tail for sewing <br></br>
Use the blue to sew on 3 claws <br></br>
<br></br>
Now you can assemble choncc!! <br></br>
</p>

<h2>Assembly</h2>
<p className="patternAssembly">
    <img src ="/images/patterns/choncc/chonccparts.png" alt="choncc parts"></img>
    <img src ="/images/patterns/choncc/chonccfinish.png" alt="choncc construction"></img>
</p>
<p>Congrats on finishing!!! <br>
</br>
Tag: @dango.ploof on insta to show me your chonccs!!</p>
            <div className="patterns">
                <a href="/patterns">Back to Patterns</a>
            </div>
    </section>
    );
}