import React from 'react'
import '../css/site.css';

export default function OrderList() {
    //need to change this to take in an array for the custom orders and status like a state machine
    return(
        <div className="card-row no-wrap">
            <div className="purple-card">
                <h3>Status</h3>
                <p>Comissions: Open!
                    <br></br>
                    <br></br>
                    <img height="210px" src="images/ducktayto.png" alt="ducktayto personal growth"/>
                </p>
                <br></br>
            </div>
            <div className="card transparent">
                <h3>Currently Working on</h3>                
                <h4>Commissions</h4>
                <p> 1.<br></br>
                </p>
                <h4>Upcoming Patterns</h4>
                <p> TFT Dowsie (free)<br></br>
                    TFT Dango (free)<br></br>
                    Fat Bean-Shaped Bird <br></br>
                    TFT Shork
                </p>
                <h4>Personal Projects</h4>
                <p>
                Camellia Sweater<br/>
                Skyscraper Vest</p>
                <br></br>
            </div>
            <div className="card transparent">
                <h3>Recent Amigurumi</h3>
                <img className="card-img" src="images/gallery/yoimiya.jpg" alt="Yoimiya"/>
                <p>Yoimiya<br></br>
                8/9/24</p>
            </div>
            <div className="card transparent">
                <h3>Recent Amigurumi</h3>
                <img className="card-img" src="images/gallery/tubby.jpg" alt="big mushroom"/>
                <p>Tubby the Shroom<br></br>
                    7/4/24</p>
            </div>
        </div>
    );
}