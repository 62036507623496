import React from 'react'
import '../css/site.css';

export default function Navigation() {
    return(
        <header>
        <div className="navigation-bar  sticky">
            <p className="navigation-item"><a href="/about">About</a></p>
            <p className="navigation-item"><a href="patterns">Free Patterns</a></p>
            {/* <div className="navigation-item"><p><a href="shop">Shop</a></p></div> */}
            <div className="navigation-item"><a href="/"><img id="dango" src="/images/dango_fin.png" alt="Dango logo"/></a></div>
            <p className="navigation-item"><a href="commissions">Commissions</a></p>
            <p className="navigation-item"><a href="gallery">Gallery</a></p>
            {/* <div className="navigation-item"><p><a href="pay">Cart</a></p></div> */}
        </div>
        </header>
    );
}