import React from 'react'

export default function Dowsie() {
    return(
        <section className="small-section">
            <h2>Dowsie Pattern</h2>
            {/* <img width="20%" src="/images/choncc.jpg" alt="choncc"></img> */}
            <h2>!!! This pattern is currently under construction !!!</h2>
            <p>Please do let me know if you notice any mistakes or typos! (An Instagram Dm or email will work!)</p>
            <h3>Yarn needed:</h3>
            <p>
            <ul>
                <li>Blue</li>
                <li>White</li>
                <li>Pink</li>
            </ul>
    (This pattern makes the basic dowsie, but changing the colors can make other variations) <br />
</p>background-color
<h3>Abbreviations: </h3>
<p>
    <ul>
        <li>sc = single crochet</li>
        <li>mr = magic ring</li>
        <li>inc = increase</li>
        <li>dec = decrease</li>
        <li>flo = front loop only</li>
    </ul>

</p>

<h3>Hat</h3>
<p>
Start with Blue<br />
Round 1: 6 sc in a mr (6) <br />
Round 2: (1 sc, 1 inc)* repeat around (9) <br />
Round 3: 1 sc around (9) <br />
Round 4: (2sc, 1 inc) (12) <br />
Round 5: (3sc, 1 inc) (15) <br />
Round 6-7: 1 sc around (15) <br />
Round 8: (1 sc, 1 dec)* repeat around (11) <br />
Round 9-10: 1 sc around (11) <br />
​Round 11: (2 sc, 1 inc)* 3, 1 sc (14) <br /> 
Round 12-15: 1 sc around (14) <br />
Round 16: (3 sc, inc, 2 sc, inc)* 2 (18) <br />
Round 17: 1 sc around (18) <br />
Round 18: (flo) (2 sc, inc)* repeat around (24) <br />
Round 19: (3 sc, inc)* repeat around (30) <br />
Round 20: (4 sc, inc)* repeat around (36) <br />
Round 21: (5 sc, inc)* repeat around (42) <br />
Round 22: (6 sc, inc)* repeat around (48) <br />
Round 23: (7 sc, inc)* repeat around (54) <br />
Round 24: (8 sc, inc)* repeat around (60) <br />
Round 25: (9 sc, inc)* repeat around (66) <br />
Round 26: (10 sc, inc)* repeat around (72) <br />
Round 27: (11 sc, inc)* repeat around (78) <br />
Cut and sew off! <br />
</p>

<h3>Head</h3>
<p>
Start with Blue <br />
Round 1: 6 sc in a mr (6) <br />
Round 2: (1 inc)* repeat around (12) <br />
Round 3: (1 sc, inc)* repeat around (18) <br />
Round 4: (2 sc, 1 inc)* repeat around (24) <br />
Round 5: (3 sc, 1 inc)* repeat around (30) <br />
Round 6: (4 sc, 1 inc)* repeat around (36) <br />
Round 7-9: 1 sc around (36) <br />
Round 10: 14 sc blue, 8 sc white, 14 sc blue (36) <br />
Round 11: 13 sc blue, 10 sc white, 13 sc blue (36) <br />
Round 12-14: 12 sc blue, 11 sc white, 12 sc blue (36) <br />
Round 15: 2 sc, 1 dec, 4 sc, 1 dec, 3 sc blue, 1 sc, 1 dec (4 sc, 1 dec)* 2 white, 4 sc, 1 dec, 2 sc blue (30) <br />
Round 16: 11 sc blue, 12 sc white, 8 sc blue (30) <br />
Round 17: (3 sc, 1 dec)* 2, 2 sc blue, 1 sc, 1 dec, 3 sc, 1 dec, 2 sc white, 1 sc, 1 dec, 3 sc, 1 dec blue (24) <br />
Round 18: (2 sc, 1 dec)* 2, 1 sc blue, 1 sc, (2 sc, 1 dec)* 3 white, 2 sc, 1 dec blue (18)
Round 19: (1 sc, 1 dec)* 3 blue, 1 sc, 1 dec, 1 sc white, 1 dec, 1 sc, 1 dec blue(12)
Round 20: 6 dec blue (6)
</p>

<h3>The rest is wip - Ears, Body, Arm fins, Tail fins, Wand</h3>

<p>Congrats on finishing!!! <br>
</br>
Tag: @dango.ploof on insta to show me your chonccs!!</p>
            <div className="patterns">
                <a href="/patterns">Back to Patterns</a>
            </div>
    </section>
    );
}